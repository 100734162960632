<template>
	<div class="container csc">
		<div class="top csc">
			<div class="search-box rec">
				<el-input placeholder="请输入商品名称" v-model="params.keyword" @change="getproductList" clearable></el-input>
				<el-button type="primary">
					<div class="rac">
						<img src="../../assets/common/shopping_cart.png" width="25" height="25"><span
							style="font-size: 1.1rem;">购物车</span>
					</div>
				</el-button>
				<div class="hotwords">
					<span>服装</span>
					<span>宝宝</span>
				</div>
			</div>
			<div class="mall-content rbs" @mouseleave="mouseout">
				<div class="menu-box df fdc jcsb">
					<div class="menu-item df aic" @mouseenter="mouseenter(index,item.id)"
						v-for="(item, index) in menuList" :key="index">
						<div :class="{active:activeIndex==index?true:false}" class="menu-item-desc df aic jcsb">
							<div>{{item.title}}</div>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
				</div>
				<div class="menu-wrap df aic fww" :style="{display:show?'flex':'none'}">
					<div class="item text_over aic" @click="$go(`/productDetail?id=${item.id}`)" v-for="item in list"
						:key="item.id">
						<img :src="item.image" alt="">
						<span class="">{{item.product_name}}</span>
					</div>
				</div>
				<div class="banner">
					<el-carousel height="370px" :interval="6000">
						<el-carousel-item v-for="item in data.bannerList" :key="item.id">
							<div class="carousel rcc">
								<img :src="item.image">
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>

		<div class="list rss fww">
			<div class="goods-item" v-for="(item, index) in productList" :key="index"
				@click="$go(`/productDetail?id=${item.id}`)">
				<img :src="item.image" width="235" height="235">
				<div class="panel">
					<div class="lines-1">
						{{item.product_name}}
					</div>
					<div class="goods-price"><span>￥{{item.price}}</span><span class="desc">{{item.sales}}人购买</span>
					</div>
				</div>
			</div>
		</div>
		<!-- <el-pagination style="margin-bottom: 50px;" layout="prev, pager, next" :total="50">
		</el-pagination> -->
	</div>
</template>

<script>
	import _ from 'lodash'
	export default {
		data() {
			return {
				data: {},
				input: '',
				menuList: [],
				activeIndex: null,
				show: false,
				params: {
					keyword: '',
					page: 1,
					limit: 99,
					cate: null
				},
				list: [],
				productList: []
			};
		},
		mounted() {
			this.shopIndex()
			this.cateList()
		},
		methods: {
			debounce: _.debounce(async function() {
				let {
					list
				} = await this.$api.productList(this.params)
				this.list = list
			}, 150),
			mouseenter(index, id = '') {
				this.activeIndex = index;
				this.show = true;
				this.params.cate = id
				this.debounce()
			},
			async getproductList() {
				let {
					list
				} = await this.$api.productList({
					keyword: this.params.keyword
				})
				this.productList = list
			},
			mouseout() {
				this.show = false;
				this.activeIndex = null
			},
			async cateList() {
				this.menuList = await this.$api.cateList()
			},
			async shopIndex() {
				this.data = await this.$api.shopIndex()
				this.productList = this.data.productList
			}
		}
	};
</script>

<style scoped lang="scss">
	.container {
		margin: 0 auto;
		background: #F5F5F5;

		/deep/ .el-pager li {
			background-color: #F5F5F5;
		}

		/deep/ .el-pagination button:disabled {
			background-color: #F5F5F5;
		}

		/deep/ .el-pagination .btn-next,
		/deep/ .el-pagination .btn-prev,
			{
			background: center center no-repeat #F5F5F5;
		}
	}

	.top {
		width: 100%;
		// height: 143px;
		height: 530px;
		background: #fff;
		background-size: 100%;
		position: relative;

		.search-box {
			width: 1200px;
			margin-top: 27px;
			position: relative;

			.hotwords {
				position: absolute;
				bottom: -25px;
				left: 290px;
				font-size: .9rem;
				color: #999;
				cursor: pointer;

				span {
					margin-right: 20px;
				}

				span:hover {
					color: #409EFF;
				}
			}



			.el-input {
				width: 714px;
				margin-right: 40px;
				border: 1.7px solid #3F7CF8;

				/deep/ .el-input__inner {
					border: none;
					width: 7132x;
					height: 49px;
					font-size: 1rem;
					color: #000;
				}
			}

			.el-button {
				width: 160px;
				height: 50px;
			}
		}
	}

	.list {
		padding: 45px 0 60px;
		width: 1200px;

		.goods-item {
			width: 235px;
			height: 310px;
			overflow: hidden;
			margin-right: 5px;
			margin-top: 5px;
			cursor: pointer;

			img {
				display: block;
			}

			.panel {
				height: 75px;
				background: #fff;
				padding: 10px;
				font-size: 1rem;
				color: #333;

				.goods-price {
					margin-top: 5px;
					color: #FF4200;

					.desc {
						margin-left: 17px;
						color: #B1B1B1;
						font-size: .8rem;
					}
				}
			}
		}
	}

	.mall-content {
		width: 1200px;
		height: 370px;
		position: relative;
		margin-top: 60px;
		background: rgba($color: #000000, $alpha: .5);
		box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: .5);

		.menu-box {
			width: 260px;
			height: 100%;
			position: relative;

			.menu-item {
				cursor: pointer;
				flex: 1;
				width: 100%;

				.menu-item-desc {
					width: 100%;
					height: 100%;
					color: #fff;
					padding: 0 9px;
					font-size: .9rem;
					// border-bottom: 1px solid #d1d1d1;
				}

				.active {
					color: #409EFF;
					background: #fff;
				}
			}
		}

		.menu-wrap {
			padding: 0 30px;
			position: absolute;
			width: 940px;
			height: 370px;
			background: #fff;
			z-index: 99;
			left: 260px;
			top: 0;

			.item {
				&:hover {
					color: #409EFF;
				}

				cursor: pointer;
				width: 25%;

				img {
					margin-right: 10px;
					width: 50px;
					height: 50px;
				}
			}
		}

		.banner {
			width: 938px;
			height: 100%;
			background: grey;

			.carousel {
				img {
					width: 100%;
					height: 370px;
				}
			}

			.el-carousel__item h3 {
				color: #475669;
				font-size: 14px;
				opacity: 0.75;
				line-height: 150px;
				margin: 0;
			}

			.el-carousel__item:nth-child(2n) {
				background-color: #99a9bf;
			}

			.el-carousel__item:nth-child(2n+1) {
				background-color: #d3dce6;
			}
		}
	}
</style>
